<template>
  <div class="searchs">
    <web-header borderBottom></web-header>
    <div class="searchs-wrap" v-loading="loading">
      <div class="searchs-wrap-header">
        <div class="searchs-wrap-header-cn">搜索结果</div>
        <div class="searchs-wrap-header-box">
          <div class="searchs-wrap-header-box-cell">
            <el-input v-model="subForm.author" placeholder="作者" @keyup.native.enter="searchForm" clearable></el-input>
          </div>
          <div class="searchs-wrap-header-box-cell">
            <el-input v-model="subForm.keyword" placeholder="关键词" @keyup.native.enter="searchForm" clearable></el-input>
          </div>
          <div class="searchs-wrap-header-box-cell">
            <el-button type="danger" @click="searchForm">搜索</el-button>
          </div>
        </div>
      </div>
      <div class="searchs-wrap-list">
        <div class="searchs-wrap-list-cell" v-for="(item, index) in dataList" :key="index">
          <a :href="creatUrl(item)" target="_blank" class="searchs-wrap-list-cell-left">
            <div class="title">{{ item.title }}</div>
            <div class="desc" v-if="item.introduction">
              {{ item.introduction }}
            </div>
            <div class="tips">
              <span>{{ $moment(item.send_time).format("YYYY-MM-DD") }}</span>
              <span v-if="item.author">{{ item.author }}</span>
            </div>
          </a>
<!--          <a :href="'/article/1/'+item.id" target="_blank" class="exchange-wrap-list-cell-right" v-if="item.cover_image && item.cover_image.img">-->
<!--            <img :src="item.cover_image.img" />-->
<!--          </a>-->
        </div>
        <el-empty description="暂无数据" v-if="!dataList || dataList.length == 0"></el-empty>
      </div>
      <div class="searchs-wrap-pagination" v-if="page.total > page.pageSize">
        <el-pagination
            background
            @current-change="handleCurrentChange"
            :current-page="page.current"
            :page-size="page.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="page.total">
        </el-pagination>
      </div>
      <div class="searchs-wrap-page">
        <el-button type="danger" size="medium" round @click="dataMore" v-if="!noneMore && page.total > page.pageSize">查看更多</el-button>
        <div class="none-more" v-else>没有更多了</div>
      </div>
    </div>
    <web-footer></web-footer>
    <web-right-menu></web-right-menu>
  </div>
</template>

<script>
import WebHeader from "@/components/webHeader";
import WebFooter from "@/components/webFooter";
import WebRightMenu from "@/components/webRightMenu";
import { generalSearch } from "@/api";
export default {
  name: "Searchs",
  components: {
    WebHeader,
    WebFooter,
    WebRightMenu
  },
  data() {
    return {
      loading: false,
      dataList: [],
      subForm: {
        author: "",
        keyword: ""
      },
      // 分页
      page: {
        // 当前页
        current: 1,
        // 每页显示的条数
        pageSize: 10,
        orderBy: "send_time",
        sortedBy: "desc",
        // 总条数
        total: 0
      },
      // 没有更多了
      noneMore: false,
      isMobile: false
    }
  },
  created() {
    this.getParams();
    this.getDataList();
  },
  mounted() {
    this.checkMobile();
    window.addEventListener('resize', this.checkMobile);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkMobile);
  },
  methods: {
    // 判断是否是手机端
    checkMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
    // url的链接生成
    creatUrl(row) {
      if (row.jump_link) {
        return row.jump_link;
      } else {
        switch (row.model_id) {
          case 1:
            return `/article/1/${row.id}`;
          case 4:
            return `/person/${row.id}`;
          case 5:
            return `/book/${row.id}`;
          default:
            return "/";
        }
      }
    },
    // 获取参数
    getParams() {
      this.page.current = /^[1-9]\d*$/.test(this.$route.query.page) && Number(this.$route.query.page) > 0 ? Number(this.$route.query.page) : 1;
      this.subForm.author = this.$route.query.author;
      this.subForm.keyword = this.$route.query.keyword;
    },
    // 修改当前URL参数
    updateUrl() {
      // 获取当前URL主域名
      let url = window.location.origin;
      url = `${url}/search?page=${this.page.current}`;
      if (this.subForm.keyword) {
        url = `${url}&keyword=${encodeURIComponent(this.subForm.keyword)}`;
      }
      if (this.subForm.author) {
        url = `${url}&author=${encodeURIComponent(this.subForm.author)}`;
      }
      history.replaceState(null, null, url);
    },
    // 搜索
    searchForm() {
      this.page.current = 1;
      this.dataList = [];
      this.getDataList();
    },
    // 获取数据
    async getDataList() {
      let param = {
        pageSize: this.page.pageSize,
        currentPage: this.page.current,
        orderBy: this.page.orderBy,
        sortedBy: this.page.sortedBy,
        search: ``,
        searchFields: ""
      };
      if (this.subForm.author || this.subForm.keyword) {
        this.loading = true;
        if (this.subForm.author) {
          param.search = `author:${this.subForm.author}`;
          param.searchFields = `author:like`;
        }
        let res = await generalSearch(this.subForm.keyword, param);
        if (res && res.code == 200) {
          if (this.isMobile) {
            this.dataList = this.dataList.concat(res.data.list);
          } else {
            this.dataList = res.data.list;
          }
          this.page.total = res.data.total;
        }
        this.loading = false;
      } else {
        this.$message.warning("请输入关键词进行搜索");
      }
      this.updateUrl();
    },
    // 分页，选中的分页
    handleCurrentChange(val) {
      this.page.current = val;
      this.getDataList();
    },
    // 加载更多
    dataMore() {
      if (this.page.total > this.page.current*this.page.pageSize) {
        this.page.current = this.page.current + 1;
        this.getDataList();
      } else {
        this.noneMore = true;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.searchs {
  &-wrap {
    max-width: 1230px;
    margin: 0 auto;
    padding-top: 50px;
    &-header {
      position: relative;
      text-align: left;
      &-cn {
        font-size: 32px;
        text-align: center;
        font-family: Georgia_Bold;
        color: #333333;
      }
      &-box {
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        &-cell {
          margin-right: 10px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
    &-list {
      margin: 50px auto 30px auto;
      &-cell {
        color: #999999;
        padding: 30px;
        background-color: #f5f5f5;
        margin-bottom: 30px;
        display: flex;
        &-left {
          padding-right: 30px;
          flex: 1;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          .title {
            color: #1e1e1e;
            font-size: 18px;
            font-family: "Georgia_Bold";
          }
          .desc {
            margin-top: 30px;
            font-size: 14px;
            color: #999999;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .tips {
            margin-top: 30px;
            display: flex;
            align-items: center;
            span {
              padding-right: 10px;
              &:after {
                content: '|';
                padding-left: 10px;
              }
              &:last-child {
                padding: 0;
                &:after {
                  content: "";
                }
              }
            }
          }
        }
        &-right {
          max-width: 220px;
          line-height: 0;
        }
        &:hover {
          box-shadow: 0px 0px 10px 10px #f5f5f5;
          background: #ffffff;
        }
      }
    }
    &-pagination {
      text-align: center;
      margin: 80px 0;
      ::v-deep {
        .el-pagination.is-background .el-pager li:not(.disabled):hover {
          color: #ed6f33;
        }
        .el-pagination.is-background .el-pager li:not(.disabled).active {
          background-color: #ed6f33;
        }
        .el-pagination.is-background .el-pager li:not(.disabled).active:hover {
          color: #ffffff;
        }
      }
    }
    &-page {
      display: none;
    }
  }
}
/* 针对平板 */
@media screen and (min-width: 768px) and (max-width: 1024px) {}
/* 针对手机 */
@media screen and (max-width: 767px) {
  .searchs {
    &-wrap {
      width: 100%;
      &-header {
        &-box {
          flex-direction: column;
          &-cell {
            margin-right: 0;
            margin-top: 15px;
            &:first-child {
              margin-top: 0;
            }
          }
        }
      }
      &-list {
        &-cell {
          padding: 15px 0;
          flex-direction: column-reverse;
          &-left {
            margin-top: 10px;
            padding-right: 0;
            .title {
              font-size: 16px;
            }
            .desc {
              margin-top: 10px;
              font-size: 12px;
            }
            .tips {
              font-size: 12px;
              margin-top: 10px;
            }
          }
          &-right {
            max-width: 100%;
          }
        }
      }
      &-pagination {
        display: none;
      }
      &-page {
        margin: 40px auto 30px auto;
        display: table;
        .none-more {
          font-size: 12px;
          padding: 5px 15px;
          border: 1px solid #DCDFE6;
        }
      }
    }
  }
}
</style>